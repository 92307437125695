body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
p,
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0 !important;
}
a {
  margin: 0;
  text-decoration: none !important;
  color: #fff !important;
}
body::-webkit-scrollbar {
  width: 0px !important;
}

body::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

body::-webkit-scrollbar-thumb {
  background-color: darkgrey;
  outline: 0.5px solid slategrey;
}
::-webkit-scrollbar {
  width: 15px;
  height: 15px;
}

::-webkit-scrollbar-track-piece {
  background-color: #c2d2e4;
}

::-webkit-scrollbar-thumb:vertical {
  height: 30px;
  background-color: #0a4c95;
}
* {
  margin: auto;
  padding: auto;
  box-sizing: border-box !important;
  font-family: "Noto Serif", serif;
}
:root {
  --header-button: #000000;
  --section-button: #219653;
  --banner-font: #ffffff;
  --learning-path-text: #828282;
  --section-text: #161616;
}
/* input {
  width: 100%;
  background: none;

  outline: none;
  padding: 0 15px;
  padding-right: 30px;
} */
